import { log } from '../../../utils/log'
import { DefaultService } from '../../../api/agentapi'
import { IsDesktopApp } from './DesktopAppApi'
import { sleep } from '../../../utils/sleep'
import { startAgentIfSupported } from './startAgent'

export const initiateAgentCredentials = async (refreshToken: string, retries: number = 1): Promise<void> => {
  if (!IsDesktopApp()) {
    log.info('trying to initiate agent credentials, not in desktop app context')
    return
  }

  try {
    await DefaultService.isAlive({})
  } catch (e) {
    if (retries > 0) {
      const started = await startAgentIfSupported()
      if (started) {
        log.info('Try to start the agent', e)
        await sleep(1500)
      }
      log.warn('Retrying to check if agent is alive', e)
      return initiateAgentCredentials(refreshToken, retries - 1)
    }
    log.warn('Cannot initiate credentials, agent is down', e)
    return
  }

  try {
    await DefaultService.updateCoreCredentials({ requestBody: { RefreshToken: refreshToken } })
  } catch (e) {
    if (retries > 0) {
      await sleep(100)
      log.warn('Retrying to initiate agent credentials', e)
      return initiateAgentCredentials(refreshToken, retries - 1)
    }
    log.error('Error in initiating agent credentials', e)
    return
  }
}
