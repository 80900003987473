import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { errorToast, infoToast } from '../../utils/toast'
import { RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { isCheckoutFailedWithPendingChanges, isCheckoutFailedWithNewerChanges } from '../../utils/errorClassify'

export const useCheckoutAsync = (
  repoId: string | undefined,
  workspaceId: string | undefined,
  onHasPendingChanges: (branchId: string, workspaceId: string) => void
) => {
  const postAnalytics = useAnalytics()
  const { refresh: refreshWorkspaces } = useWorkspaces(repoId)
  const { refresh: refreshWorkspace } = useWorkspace(repoId, workspaceId)
  return useCallback(
    async (branchId: string) => {
      if (isEmpty(workspaceId)) {
        errorToast('No workspace selected')
        return false
      }
      try {
        await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceCheckout({
          repoId: repoId!,
          workspaceId: workspaceId!,
          requestBody: {
            ref_id: branchId,
          },
        })
      } catch (e) {
        if (isCheckoutFailedWithPendingChanges(e)) {
          onHasPendingChanges(branchId, workspaceId!)
          return true
        } else {
            if (isCheckoutFailedWithNewerChanges(e)) {
                errorToast('Workspace got new changes since checkout started. Try again.')
                return
            }
            else {
                throw e
            }
        }
      }
      infoToast('Branch switched successfully', true)
      postAnalytics('BranchCheckedOut', {
        repo_id: repoId,
        branch_id: branchId,
        workspace_id: workspaceId,
      })
      refreshWorkspace()
      refreshWorkspaces()
      return false
    },
    [onHasPendingChanges, postAnalytics, refreshWorkspace, refreshWorkspaces, repoId, workspaceId]
  )
}
