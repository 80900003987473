import { OpenAPI as CoreApiConfig } from './coreapi/core/OpenAPI'
import { OpenAPI as AgentApiConfig } from './agentapi/core/OpenAPI'
import config from '../env/config'
import { log } from '../utils/log'
import uniqueId from 'lodash/uniqueId'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { readFile } from '@todesktop/client-fs'

const SENTRY_CORRELATION_ID = 'X-Sentry-Correlation-ID'

export const configureCoreApi = () => {
  CoreApiConfig.BASE = `${config.API_URL}/v0`
  CoreApiConfig.HEADERS = {
    ...CoreApiConfig.HEADERS,
    'x-dv-app-name': config.APP_NAME,
    'x-dv-app-version': config.APP_VERSION,
    [SENTRY_CORRELATION_ID]: uuidv4(),
  }
  CoreApiConfig.DEFAULT_TIMEOUT_MILLISEC = config.REQUEST_TIMEOUT_SEC * 1000
  log.info('set up core api', CoreApiConfig)
}

const getAgentPort = async (): Promise<string> => {
  const defaultAgentPort = config.AGENT_PORT!
  if (window.todesktop?.fs === undefined) {
    log.info('toDesktop fs is not available')
    return defaultAgentPort
  }
  const envSuffix = config.IS_DEV_ENV ? '.test' : ''
  const portFilePath = `.diversion/.port${envSuffix}`
  try {
    const portFileContent = await readFile('home', portFilePath)
    const portNumber = parseInt(portFileContent)
    if (isNaN(portNumber)) {
      log.error(`Invalid agent port number in file: ${portFilePath}`, portFileContent)
      return defaultAgentPort
    }
    log.info(`Read agent port file: ${portFilePath}`, portNumber)
    return portNumber.toString()
  } catch (err) {
    log.error(`Failed to read agent port file: ${portFilePath}`, err)
  }
  return defaultAgentPort
}

export const configureAgentApi = () => {
  getAgentPort().then((agentPort) => {
    AgentApiConfig.BASE = `http://localhost:${agentPort}`
    AgentApiConfig.DEFAULT_TIMEOUT_MILLISEC = config.REQUEST_TIMEOUT_SEC * 1000
    log.info('set up agent api', AgentApiConfig)
  })
}

export const clientId = () => `web_${uniqueId()}`

axios.interceptors.request.use(
  (requestConfig) => {
    const headers = requestConfig.headers || {}
    headers[SENTRY_CORRELATION_ID] = uuidv4()
    requestConfig.headers = headers
    return requestConfig
  },
  (error) => {
    if (!isEmpty(error?.config?.headers?.Authorization)) {
      delete error.config.headers.Authorization
    }
    return Promise.reject(error)
  }
)
