/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthNotification } from '../models/AuthNotification';
import type { CloneRepo } from '../models/CloneRepo';
import type { CoreApiCredentials } from '../models/CoreApiCredentials';
import type { FileSyncStatusList } from '../models/FileSyncStatusList';
import type { InitRepo } from '../models/InitRepo';
import type { OpenWorkspaceFileParams } from '../models/OpenWorkspaceFileParams';
import type { PathList } from '../models/PathList';
import type { SupportBundle } from '../models/SupportBundle';
import type { SupportBundleResponse } from '../models/SupportBundleResponse';
import type { WorkspaceConfiguration } from '../models/WorkspaceConfiguration';
import type { WorkspaceSyncProgress } from '../models/WorkspaceSyncProgress';
import type { WorkspaceSyncStatus } from '../models/WorkspaceSyncStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Is alive sanity check
     * @returns any OK
     * @throws ApiError
     */
    public static isAlive({
        dumpTrace = false,
    }: {
        dumpTrace?: boolean,
    }): CancelablePromise<{
        Version?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/health',
            query: {
                'dump_trace': dumpTrace,
            },
        });
    }
    /**
     * Get coreapi access token for the current user
     * @returns any OK
     * @throws ApiError
     */
    public static coreAccessToken(): CancelablePromise<{
        AccessToken: string;
        /**
         * Expiration Unix timestamp in seconds
         */
        ExpiresAt: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/core',
            errors: {
                400: `No authenticated user`,
            },
        });
    }
    /**
     * Update / override coreapi credentials
     * @returns any OK
     * @throws ApiError
     */
    public static updateCoreCredentials({
        requestBody,
    }: {
        requestBody: CoreApiCredentials,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/core',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all workspaces
     * @returns WorkspaceConfiguration OK
     * @throws ApiError
     */
    public static getAllWorkspaces(): CancelablePromise<Record<string, WorkspaceConfiguration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces',
        });
    }
    /**
     * Get workspace configuration for the given path
     * @returns WorkspaceConfiguration OK
     * @throws ApiError
     */
    public static getWorkspaceByPath({
        absPath,
    }: {
        absPath: string,
    }): CancelablePromise<Record<string, WorkspaceConfiguration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspace',
            query: {
                'abs_path': absPath,
            },
        });
    }
    /**
     * Create repo and workspace from local directory
     * @returns any OK
     * @throws ApiError
     */
    public static repoInit({
        requestBody,
    }: {
        requestBody: InitRepo,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repo/init',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `User error`,
            },
        });
    }
    /**
     * Get workspaces by repo ID
     * @returns WorkspaceConfiguration OK
     * @throws ApiError
     */
    public static getWorkspaces({
        repoId,
    }: {
        repoId: string,
    }): CancelablePromise<Record<string, WorkspaceConfiguration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspaces',
            path: {
                'RepoID': repoId,
            },
        });
    }
    /**
     * Get workspace by ID
     * @returns WorkspaceConfiguration OK
     * @throws ApiError
     */
    public static getWorkspace({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<WorkspaceConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
        });
    }
    /**
     * Add or update a cloned workspace
     * @returns any OK
     * @throws ApiError
     */
    public static addOrUpdateClonedWorkspace({
        repoId,
        workspaceId,
        requestBody,
    }: {
        repoId: string,
        workspaceId: string,
        requestBody: WorkspaceConfiguration,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove a cloned workspace
     * @returns any OK
     * @throws ApiError
     */
    public static removeClonedWorkspace({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
        });
    }
    /**
     * Get workspace sync status
     * @returns WorkspaceSyncStatus OK
     * @throws ApiError
     */
    public static getWorkspaceSyncStatus({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<WorkspaceSyncStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}/sync',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
        });
    }
    /**
     * Notify of sync required for a workspace
     * @returns any OK
     * @throws ApiError
     */
    public static notifySyncRequired({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}/sync',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
        });
    }
    /**
     * Get workspace sync progress
     * @returns WorkspaceSyncProgress OK
     * @throws ApiError
     */
    public static getSyncProgress({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<WorkspaceSyncProgress> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}/sync/progress',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
            errors: {
                412: `Failed to get sync progress due to initialization or internal error state`,
            },
        });
    }
    /**
     * Scan workspace for changes
     * @returns void
     * @throws ApiError
     */
    public static notifyScanRequired({
        workspaceId,
    }: {
        workspaceId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/{WorkspaceID}/scan',
            path: {
                'WorkspaceID': workspaceId,
            },
            errors: {
                404: `Workspace with the input parameters not found`,
                503: `Workspace has errors and cannot be scanned`,
            },
        });
    }
    /**
     * Get file sync status for each path
     * @returns FileSyncStatusList OK
     * @throws ApiError
     */
    public static getFileSyncStatus({
        repoId,
        workspaceId,
        paths,
    }: {
        repoId: string,
        workspaceId: string,
        paths: PathList,
    }): CancelablePromise<FileSyncStatusList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}/files/status',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
            query: {
                'Paths': paths,
            },
            errors: {
                400: `User error`,
                412: `Failed to get file sync status due to initialization or internal error state"`,
            },
        });
    }
    /**
     * Get workspace structure dump
     * @returns binary OK
     * @throws ApiError
     */
    public static workspaceStructureDump({
        repoId,
        workspaceId,
    }: {
        repoId: string,
        workspaceId: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repo/{RepoID}/workspace/{WorkspaceID}/debug/dump',
            path: {
                'RepoID': repoId,
                'WorkspaceID': workspaceId,
            },
            responseType: 'blob',
        });
    }
    /**
     * Pause workspace sync
     * @returns any OK
     * @throws ApiError
     */
    public static pauseWorkspaceSync({
        workspaceId,
    }: {
        workspaceId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/{WorkspaceID}/sync/pause',
            path: {
                'WorkspaceID': workspaceId,
            },
            errors: {
                404: `Workspace with the input parameters not found`,
                409: `Workspace is already paused`,
            },
        });
    }
    /**
     * Resume workspace sync
     * @returns any OK
     * @throws ApiError
     */
    public static resumeWorkspaceSync({
        workspaceId,
    }: {
        workspaceId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspace/{WorkspaceID}/sync/resume',
            path: {
                'WorkspaceID': workspaceId,
            },
            errors: {
                404: `Workspace with the input parameters not found`,
                409: `Workspace is already running`,
            },
        });
    }
    /**
     * Clone repository into a local workspace
     * @returns WorkspaceConfiguration OK
     * @throws ApiError
     */
    public static cloneRepository({
        repoId,
        requestBody,
    }: {
        repoId: string,
        requestBody: CloneRepo,
    }): CancelablePromise<WorkspaceConfiguration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repo/{RepoID}/clone',
            path: {
                'RepoID': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `User error`,
            },
        });
    }
    /**
     * Generate support bundle for the agent
     * @returns SupportBundleResponse OK
     * @throws ApiError
     */
    public static generateSupportBundle({
        requestBody,
    }: {
        requestBody: SupportBundle,
    }): CancelablePromise<SupportBundleResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/support/bundle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Notify of an authentication event
     * @returns any OK
     * @throws ApiError
     */
    public static notifyOfAuthEvent({
        requestBody,
    }: {
        requestBody: AuthNotification,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notify/auth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List directory paths to suggest candidates for cloning / init repos
     * @returns any OK
     * @throws ApiError
     */
    public static listDir({
        requestBody,
    }: {
        requestBody: {
            paths?: Array<string>;
        },
    }): CancelablePromise<{
        paths: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/utils/list_dir',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Open locally cloned workspace with finder / explorer (or sub path underneath it)
     * @returns any OK
     * @throws ApiError
     */
    public static openLocalWorkspace({
        requestBody,
    }: {
        requestBody: {
            /**
             * ID of the locally cloned workspace we want to open
             */
            workspaceId: string;
            /**
             * Optional path to item under the locally cloned workspace
             */
            subPath?: string;
            /**
             * Whether to select the opened item in finder / explorer
             */
            selected?: boolean;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/utils/open_workspace',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `cloned workspace with the input parameters not found`,
            },
        });
    }
    /**
     * Open a file inside a locally cloned workspace with it's default application
     * @returns any OK
     * @throws ApiError
     */
    public static openLocalWorkspaceFile({
        requestBody,
    }: {
        requestBody: OpenWorkspaceFileParams,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/utils/open_workspace_file',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `file with the provided path was not found`,
            },
        });
    }
}
