import { DesktopAppVersion } from './DesktopAppApi'
import { execute } from '@todesktop/client-exec'

export const AgentStartMinVersionSupport = '0.4.1'

export const startAgentIfSupported = async () => {
  const desktopAppVersion = DesktopAppVersion()
  if (desktopAppVersion && execute !== undefined && desktopAppVersion >= AgentStartMinVersionSupport) {
    await execute([])
    return true
  }
  return false
}
