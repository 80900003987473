import { DefaultService} from '../../api/agentapi'
import React, { useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { errorToast, infoToast } from '../../utils/toast'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocalDirSelectionDialog } from './LocalDirSelectionDialog'
import { LocationContext } from '../../models/Analytics'


interface ReattachWorkspaceDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  onSuccess: () => void
  locationContext: LocationContext
}

export const ReattachWorkspaceDialog = ({ isOpen, setOpen, onSuccess, locationContext }: ReattachWorkspaceDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const postAnalytics = useAnalytics()

  const onClose = useCallback(() => {
    setIsLoading(false)
  }, [])
  const reattachWorkspaceCallback = useCallback(
    async (path: string) => {
      postAnalytics('ReattachWorkspaceClicked', {}, locationContext)
      setIsLoading(true)
      try {
        const res = await DefaultService.getWorkspaceByPath({
            absPath: path,
        });
        const workspace = Object.values(res)[0]
        if (workspace) {
          if (workspace.Path !== path) {
            workspace.Path = path
          }
          await DefaultService.addOrUpdateClonedWorkspace({
            repoId: workspace.RepoID,
            workspaceId: workspace.WorkspaceID,
            requestBody: workspace,
          });
          infoToast(`Repo ${workspace.RepoName} reconnected successfully`)
          onSuccess()
        } else {
            errorToast('No workspace found')
        }
      } catch (e: any) {
        errorToast()
        log.error('Unexpected error while trying to reconnect a workspace', e)
      }
      setOpen(false)
      setIsLoading(false)
    },
    [postAnalytics, locationContext, onSuccess, setOpen]
  )
  return (
    <LocalDirSelectionDialog
      isOpen={isOpen}
      setOpen={setOpen}
      title={'Reconnect Workspace'}
      buttonLabel={'Connect'}
      treeId={`reattach-workspace`}
      onSelected={reattachWorkspaceCallback}
      loading={isLoading}
      onClose={onClose}
    />
  )
}
