/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Commit } from '../models/Commit';
import type { CommitMessage } from '../models/CommitMessage';
import type { CommitRequest } from '../models/CommitRequest';
import type { Error } from '../models/Error';
import type { MergeId } from '../models/MergeId';
import type { NewCommit } from '../models/NewCommit';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryCommitManipulationService {
    /**
     * Get a list of commits. (Executed on secondary shard)
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2CommitListAll({
        repoId,
        refIds,
        limit = 100,
        skip,
        query,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * If specified, filters commit list to start with specified ref IDs (branches, workspaces etc.), otherwise return commits accessible from all branches.
         *
         */
        refIds?: Array<string>,
        /**
         * Limit the number or items returned from a listing api
         */
        limit?: number,
        /**
         * Skip a number of items returned from a listing api
         */
        skip?: number,
        /**
         * A string query to filter against in a listing api
         */
        query?: string,
    }): CancelablePromise<{
        object: 'Commit';
        items: Array<Commit>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/commits',
            path: {
                'repo_id': repoId,
            },
            query: {
                'ref_ids': refIds,
                'limit': limit,
                'skip': skip,
                'query': query,
            },
        });
    }
    /**
     * Get bulk details of commits by their reference IDs
     * @returns Commit An object describing a mapping of a commit by its ID
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2CommitGetBulk({
        repoId,
        refIds,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * List of reference IDs to include
         *
         */
        refIds: Array<string>,
    }): CancelablePromise<Record<string, Commit> | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/commits/bulk',
            path: {
                'repo_id': repoId,
            },
            query: {
                'ref_ids': refIds,
            },
        });
    }
    /**
     * Get details of a specific commit
     * @returns Commit An object describing a commit
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2CommitGet({
        repoId,
        commitId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A commit ID
         */
        commitId: string,
    }): CancelablePromise<Commit | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/commits/{commit_id}',
            path: {
                'repo_id': repoId,
                'commit_id': commitId,
            },
        });
    }
    /**
     * Update a commit message
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2CommitPatch({
        repoId,
        commitId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A commit ID
         */
        commitId: string,
        requestBody: CommitMessage,
    }): CancelablePromise<Record<string, any> | Error> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/commits/{commit_id}',
            path: {
                'repo_id': repoId,
                'commit_id': commitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Commit workspace to base branch
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @returns NewCommit Commit created
     * @throws ApiError
     */
    public static srcHandlersv2WorkspaceCommitWorkspace({
        repoId,
        workspaceId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * The repo ID of the workspace.
         */
        workspaceId: string,
        requestBody: CommitRequest,
    }): CancelablePromise<any | Error | NewCommit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/workspaces/{workspace_id}/commit',
            path: {
                'repo_id': repoId,
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `None of the commit paths exist`,
                409: `Workspace contains conflicts`,
                412: `The specified resource is no longer up to date`,
                503: `Service unavailable`,
                522: `Server service error`,
            },
        });
    }
    /**
     * Revert commit
     * The operation reverts any changes done in the single commit represented by revert_ref_id (compared to its own parent). These opposing changes are then applied into the branch represented by base_id. If any changes were done between revert_ref_id and base_id, they will be checked for conflicts with the revert changes.
     *
     * @returns any Request completed successfully
     * @returns MergeId The revert is in progress. It has merge conflicts requiring active input from the user for conflict resolution. The response contains a merge ID, which can be used for querying /repos/{repo_id}/merges/{merge_id}
     * @throws ApiError
     */
    public static srcHandlersv2RevertRevert({
        repoId,
        baseId,
        revertRefId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A reference to a base unto which changes will be applied
         */
        baseId?: string,
        /**
         * A reference to a source version to revert, currently only commit ID is supported
         */
        revertRefId?: string,
    }): CancelablePromise<any | MergeId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/revert',
            path: {
                'repo_id': repoId,
            },
            query: {
                'base_id': baseId,
                'revert_ref_id': revertRefId,
            },
            errors: {
                400: `The request does not meet the required conditions`,
                409: `The workspace is not empty, cannot revert`,
                503: `Service unavailable`,
            },
        });
    }
}
